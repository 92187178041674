import type { Customer } from '@bigcommerce/checkout-sdk';
import { fetchData, logger } from '../utils';

const BANNED_CUSTOMERS_PATH = '/content/htnyngf_customers.js';

const isEmailExistsInArrayData = (arr: [], emailToCheck: string) => {
  return arr.reduce((acc, curr: any) => {
    return acc || (curr.email && curr.email === emailToCheck);
  }, false);
};

export const customerAllowedCheck = async (customer: Customer) => {
  logger('-- Customer Allowed Check');

  try {
    const customerEmail = customer.email;
    const bannedCustomers = await fetchData(BANNED_CUSTOMERS_PATH);

    if (
      bannedCustomers &&
      bannedCustomers.length &&
      customerEmail &&
      isEmailExistsInArrayData(bannedCustomers, customerEmail)
    ) {
      logger(
        `customer (${customerEmail}) exist in data file so we should redirect customer to problem page.`,
      );
      window.location.href = '/problem-with-your-account/';
    }
  } catch (error) {
    console.log('-- error in Customer Allowed Check ', error);
  }
};
