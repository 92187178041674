// input fields
export const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
  window.HTMLInputElement.prototype,
  'value',
)?.set;

export const inputValueSetter = (element: Element, value: any) => {
  if (nativeInputValueSetter) {
    nativeInputValueSetter.call(element, value);
    if (typeof Event === 'function') {
      const inputEvent = new Event('input', { bubbles: true });
      element.dispatchEvent(inputEvent);
    }
  }
};

// select fields
export const nativeSelectValueSetter = Object.getOwnPropertyDescriptor(
  window.HTMLSelectElement.prototype,
  'value',
)?.set;

export const selectValueSetter = (element: Element, value: any) => {
  if (nativeSelectValueSetter) {
    nativeSelectValueSetter.call(element, value);
    if (typeof Event === 'function') {
      const inputEvent = new Event('change', { bubbles: true });
      element.dispatchEvent(inputEvent);
    }
  }
};

// checkbox fields
export const nativeCheckboxValueSetter = Object.getOwnPropertyDescriptor(
  window.HTMLInputElement.prototype,
  'checked',
)?.set;

export const checkboxValueSetter = (element: Element, value: any) => {
  if (nativeCheckboxValueSetter) {
    nativeCheckboxValueSetter.call(element, value);
    if (typeof Event === 'function') {
      const inputEvent = new Event('click', { bubbles: true });
      element.dispatchEvent(inputEvent);
    }
  }
};

/**
 * Helper function to set the value of various input fields
 */
export const setInputValue = (selector: string, value: any) => {
  const input = document.querySelector(selector);
  if (input) {
    switch (input.tagName) {
      case 'SELECT':
        return selectValueSetter(input, value);
      default:
        const type = input.getAttribute('type');
        return type === 'checkbox'
          ? checkboxValueSetter(input, value)
          : inputValueSetter(input, value);
    }
  }
};



/**
 * Helper function to get the value of various input fields
 */
export const getInputValue = (selector: string): any => {
  const input = document.querySelector(selector);
  if (input) {
    switch (input.tagName) {
      case 'SELECT':
        return (input as HTMLSelectElement).value;
      default:
        const type = input.getAttribute('type');
        if (type === 'checkbox') {
          return (input as HTMLInputElement).checked;
        } else {
          return (input as HTMLInputElement).value;
        }
    }
  }
  return null; // or throw an error if preferred
};
