import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger,fetchData } from '../utils';


export const customerSignedInConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CUSTOMER_SIGNED_IN,
  handler: async({ detail }) => {
    logger('Customer Signed In');
    console.log(detail);

    /*  when customer signs in on the Checkout page, redirect them to the Cart page */
    window.location.href = '/cart.php'

  },
};
