const STRINGS_TO_REMOVE = ['- OEM', '- W', '- E'];

const containsStringToRemove = (title: string) =>
  STRINGS_TO_REMOVE.some((strToRemove) => title.includes(strToRemove));

/*
 remove "OEM" from product names
*/
export const cleanProductNames = () => {
  const productTitles: NodeListOf<HTMLHeadingElement> =
    document.querySelectorAll('.product-title');

  productTitles.forEach((title) => {
    if (title.textContent && containsStringToRemove(title.textContent)) {
      const cleanText = STRINGS_TO_REMOVE.reduce(
        (text, strToRemove) => text.replace(strToRemove, ''),
        title.textContent,
      ).trim();

      title.textContent = cleanText;
    }
  });
};
