import type {
  Address,
  Cart,
  Consignment,
  Coupon,
  Customer,
  GiftCertificate,
  Order,
  PaymentMethod,
  ShippingOption,
  Tax,
} from '@bigcommerce/checkout-sdk';

export enum EBIZIO_EVENT_TYPE {
  CHECKOUT_LOADED = 'CHECKOUT_LOADED',
  CUSTOMER_SIGNED_IN = 'CUSTOMER_SIGNED_IN',
  CUSTOMER_SIGNED_OUT = 'CUSTOMER_SIGNED_OUT',
  CUSTOMER_STEP_LOADING = 'CUSTOMER_STEP_LOADING',
  SHIPPING_STEP_LOADING = 'SHIPPING_STEP_LOADING',
  SHIPPING_STEP_SUBMITTED = 'SHIPPING_STEP_SUBMITTED',
  BILLING_STEP_LOADING = 'BILLING_STEP_LOADING',
  PAYMENT_STEP_LOADING = 'PAYMENT_STEP_LOADING',
  PAYMENT_STEP_SUBMITTED = 'PAYMENT_STEP_SUBMITTED',
  SHIPPING_METHOD_SELECTED = 'SHIPPING_METHOD_SELECTED',
  STORE_CREDIT_TOGGLED = 'STORE_CREDIT_TOGGLED',
  PAYMENT_METHOD_SELECTED = 'PAYMENT_METHOD_SELECTED',
  PAYMENT_METHOD_SELECTED_BUT_ALREADY_ACTIVE = 'PAYMENT_METHOD_SELECTED_BUT_ALREADY_ACTIVE',
  PAYMENT_METHOD_LOADED = 'PAYMENT_METHOD_LOADED',
  COUPONS_CHANGED = 'COUPONS_CHANGED',
  GIFT_CERTIFICATES_CHANGED = 'GIFT_CERTIFICATES_CHANGED',
  CART_SUMMARY_MODAL_OPENED = 'CART_SUMMARY_MODAL_OPENED',
  CART_SUMMARY_MOBILE_MOUNTED = 'CART_SUMMARY_MOBILE_MOUNTED',
  CART_SUMMARY_DESKTOP_MOUNTED = 'CART_SUMMARY_DESKTOP_MOUNTED',
  SAVED_SHIPPING_ADDRESS_CHANGED = 'SAVED_SHIPPING_ADDRESS_CHANGED',
  USE_NEW_SHIPPING_ADDRESS_SELECTED = 'USE_NEW_SHIPPING_ADDRESS_SELECTED',
  REFRESH_CART_DATA_FINISHED = 'REFRESH_CART_DATA_FINISHED',
}

export enum EBIZIO_ORDER_EVENT_TYPE {
  ORDER_CONFIRMATION_LOADED = 'ORDER_CONFIRMATION_LOADED',
}

export interface EbizioAccess {
  customerGroupPaymentRestrictions: boolean;
  customerGroupShippingRestrictions: boolean;
  destinationBasedProductFees: boolean;
  eventManager: boolean;
  productFees: boolean;
  purchaseOrder: boolean;
  shipOnAccount: boolean;
  styleEditor: boolean;
  textEditor: boolean;
  dittopay: boolean;
}

export enum CheckoutStepType {
  Billing = 'billing',
  Customer = 'customer',
  Payment = 'payment',
  Shipping = 'shipping',
}

export type ApiCouponType =
  | 'per_item_discount'
  | 'percentage_discount'
  | 'per_total_discount'
  | 'shipping_discount'
  | 'free_shipping'
  | 'promotion';

export interface ApiCoupon {
  id: number;
  name: string;
  type: ApiCouponType;
  amount: string;
  min_purchase: string;
  expires: string;
  enabled: boolean;
  code: string;
  applies_to: {
    entity: 'products' | 'categories';
    ids: number[] | null;
  };
  num_uses: number;
  max_uses: number;
  max_uses_per_customer: number;
  restricted_to: [];
  shipping_methods: null;
  date_created: string;
}

export interface EbizioEventDetails {
  access: EbizioAccess;
  activeStepType: CheckoutStepType | undefined;
  cart: Cart | undefined;
  coupons: Coupon[] | undefined;
  customer: Customer;
  customerMessage: string;
  billingAddress: Address | undefined;
  giftCertificates: GiftCertificate[];
  isStoreCreditApplied: boolean;
  selectedShippingOption: ShippingOption | undefined;
  selectedPaymentMethod: PaymentMethod | null;
  shippingAddress: Address | undefined;
  shippingCostTotal: number | null;
  consignments: Consignment[] | null;
  grandTotal: number | null;
  taxTotal: number | null;
  taxes: Tax[];
  dittopayCustomCouponCode: boolean;
  dittopayCustomCoupon: ApiCoupon | null;
  refreshCartData: (navigateNextStep?: boolean) => Promise<void>;
}

export interface EbizioOrderEventDetails {
  access: EbizioAccess;
  order: Order;
}

export type EbizioEvent = CustomEvent<EbizioEventDetails>;

export interface EbizioEventHandler {
  (event: EbizioEvent): void;
}

export interface EbizioEventConfig {
  event: EBIZIO_EVENT_TYPE;
  handler: EbizioEventHandler;
}

export type EbizioOrderEvent = CustomEvent<EbizioOrderEventDetails>;

export interface EbizioOrderEventHandler {
  (event: EbizioOrderEvent): void;
}

export interface EbizioOrderEventConfig {
  event: EBIZIO_ORDER_EVENT_TYPE;
  handler: EbizioOrderEventHandler;
}
