import { cleanProductNames } from '../custom';
import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const cartSummaryModalOpenedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CART_SUMMARY_MODAL_OPENED,
  handler: ({ detail }) => {
    logger('Cart Summary Modal Opened');
    console.log(detail);

    cleanProductNames();
  },
};
