export const fetchData = async (url: string) => {
  const config = {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(url, config);

  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    throw new Error(message);
  }

  return await response.json();
}
