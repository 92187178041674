import type { Cart } from '@bigcommerce/checkout-sdk';
import $ from 'cash-dom';

import { getInputValue, logger, setInputValue } from '../utils';
import { updateCustomerMessage } from '../utils/api/checkout';
import { trim } from 'lodash';

const poNumberElement = "input[name='customFields.enter-your-po-number']";
const orderCommentElement =
  "input[name='customFields.enter-any-comments-about-your-order']";

const customFieldset = `
  <div>
    <fieldset id="custom-fieldset--purchaseOrder" class="form-fieldset form-fieldset--purchaseOrder">
      <legend class="form-legend optimizedCheckout-headingSecondary">Purchase Order</legend>
      <div class="form-body">
        <div class="dynamic-form-field dynamic-form-field--enter-your-po-number">
          <div class="form-field">
            <label for="enter-your-po-numberInput" class="form-label optimizedCheckout-form-label">
              Enter your PO number: <small class="optimizedCheckout-contentSecondary">(Optional)</small>
            </label>
            <input maxlength="30" id="enter-your-po-numberInput" name="customFields.enter-your-po-number" class="form-input optimizedCheckout-form-input" type="input" data-test="enter-your-po-numberInput-text" value="" autocomplete="nzwdw">
          </div>
        </div>
      </div>
    </fieldset>
  </div>
  <div>
    <form id="checkout-custom-form--orderComments" class="custom-form--orderComments" data-test="checkout-custom-form--orderComments" novalidate="">
      <fieldset id="custom-fieldset--orderComments" class="form-fieldset form-fieldset--orderComments">
        <legend class="form-legend optimizedCheckout-headingSecondary">Order Comments</legend>
        <div class="form-body">
          <div class="dynamic-form-field dynamic-form-field--enter-any-comments-about-your-order">
            <div class="form-field">
              <label for="enter-any-comments-about-your-orderInput" class="form-label optimizedCheckout-form-label">
                Enter any comments about your order: <small class="optimizedCheckout-contentSecondary">(Optional)</small>
              </label>
              <input maxlength="200" id="enter-any-comments-about-your-orderInput" name="customFields.enter-any-comments-about-your-order" class="form-input optimizedCheckout-form-input" type="input" data-test="enter-any-comments-about-your-orderInput-text" value="" autocomplete="pkm70">
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
`;

export class CustomizeOrderComment {
  cart: Cart;
  refreshCartData: (navigateNextStep?: boolean) => Promise<void>;
  customerMessage: string;
  debounceTimeout: number | null = null;

  constructor(
    cart: Cart,
    customerMessage: string,
    refreshCartData: (navigateNextStep?: boolean) => Promise<void>,
  ) {
    logger('-- customize-order-comment Script');
    this.cart = cart;
    this.customerMessage = customerMessage;
    this.refreshCartData = refreshCartData;

    this.onReady();
  }

  parseCustomerMessage() {
    const poNumberMatch = this.customerMessage.match(/PO Number:\s*([^}]+)/);
    const po_number = poNumberMatch ? trim(poNumberMatch[1]) : '';

    const orderCommentsMatch = this.customerMessage.match(
      /Order Comments:\s*([^}]+)/,
    );
    const order_comments = orderCommentsMatch
      ? trim(orderCommentsMatch[1])
      : '';

    return {
      po_number,
      order_comments,
    };
  }

  async initFieldsValue() {
    if (this.customerMessage) {
      const data = this.parseCustomerMessage();
      setInputValue(poNumberElement, data.po_number);
      setInputValue(orderCommentElement, data.order_comments);
    }
  }

  async fieldsChange() {
    let customMessage = '';

    const data = this.parseCustomerMessage();

    if (this.cart?.coupons && this.cart?.coupons.length) {
      const coupon = this.cart?.coupons[0];
      if (coupon.code && coupon.discountedAmount) {
        customMessage += ` Coupon: ${coupon.code}: -${coupon.discountedAmount};`;
      }
    }

    const poNumber = getInputValue(poNumberElement);
    if (poNumber) {
      customMessage += ` | { PO Number: ${poNumber} } |`;
    }

    const orderCommentValue = getInputValue(orderCommentElement);
    if (orderCommentValue) {
      customMessage += ` { Order Comments: ${orderCommentValue} }`;
    }

    if (
      data.po_number != poNumber ||
      data.order_comments != orderCommentValue
    ) {
      await updateCustomerMessage(customMessage);
      await this.refreshCartData();
    }
  }

  debounceFieldsChange() {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = window.setTimeout(() => this.fieldsChange(), 600);
  }

  bindEvents() {
    $(poNumberElement).on('keyup', this.debounceFieldsChange.bind(this));
    $(orderCommentElement).on('keyup', this.debounceFieldsChange.bind(this));

    // Trigger update when input loses focus
    // $(poNumberElement).on('blur', this.fieldsChange.bind(this));
    // $(orderCommentElement).on('blur', this.fieldsChange.bind(this));
  }

  async onReady() {
    $(() => {
      console.log('DOM loaded');

      if (!$(poNumberElement).length) {
        $(customFieldset).insertBefore(
          $('.checkout-step--payment .form-actions'),
        );
      }

      this.bindEvents();
      this.initFieldsValue();

      setTimeout(() => {
        this.fieldsChange();
      }, 500);
    });
  }
}
