import makeRequest from './request';
import {
  Checkout,
  Coupon,
  Consignment,
  ConsignmentLineItem,
} from '@bigcommerce/checkout-sdk';
import { CHECKOUT_ID } from '../../constants';

interface ResponseData {
  data: Checkout | null;
  message: string;
  isError: boolean;
}

interface ConsignmentBody {
  address: Consignment['address'];
  lineItems: ConsignmentLineItem[];
  shouldSaveAddress: boolean;
}

/**
 * Update the customer message
 *
 * https://developer.bigcommerce.com/api-reference/77065ea992c7e-update-customer-messages
 */
export const updateCustomerMessage = async (
  message: Checkout['customerMessage'],
): Promise<ResponseData> => {
  const options = {
    method: 'PUT',
    body: {
      customerMessage: message.trim() || '',
    },
  };

  try {
    const data = await makeRequest(`/checkouts/${CHECKOUT_ID}`, options);

    return {
      data: data,
      message: '',
      isError: false,
    };
  } catch (error) {
    console.error('error ', error);
    return {
      data: null,
      message: 'Failed to add customer message.',
      isError: true,
    };
  }
};

/**
 * Add a coupon to checkout
 *
 * https://developer.bigcommerce.com/api-reference/92da13659bc85-add-coupon-to-checkout
 */
export const addCoupon = async (
  couponCode: Coupon['code'],
): Promise<ResponseData> => {
  const options = {
    method: 'POST',
    body: {
      couponCode: couponCode.trim() || '',
    },
  };

  try {
    const data = await makeRequest(
      `/checkouts/${CHECKOUT_ID}/coupons`,
      options,
    );

    return {
      data: data,
      message: '',
      isError: false,
    };
  } catch (error) {
    console.error('error ', error);
    return {
      data: null,
      message: 'Failed to add coupon.',
      isError: true,
    };
  }
};

/**
 * Remove a coupon from checkout
 *
 * https://developer.bigcommerce.com/api-reference/600fd995ef2b7-delete-checkout-coupon
 */
export const removeCoupon = async (
  couponCode: Coupon['code'],
): Promise<ResponseData> => {
  const options = {
    method: 'DELETE',
    body: {
      couponCode: couponCode?.trim() || '',
    },
  };

  try {
    const data = await makeRequest(
      `/checkouts/${CHECKOUT_ID}/coupons/${couponCode}`,
      options,
    );

    return {
      data: data,
      message: '',
      isError: false,
    };
  } catch (error) {
    console.error('error ', error);
    return {
      data: null,
      message: 'Failed to add coupon.',
      isError: true,
    };
  }
};

/**
 * Update a consignment
 *
 * https://developer.bigcommerce.com/api-reference/68accc5f14783-update-a-consignment
 */
export const updateConsignment = async (
  consignmentId: Consignment['id'],
  consignmentBody: ConsignmentBody,
): Promise<ResponseData> => {
  const options = {
    method: 'PUT',
    body: consignmentBody,
  };

  try {
    const data = await makeRequest(
      `/checkouts/${CHECKOUT_ID}/consignments/${consignmentId}`,
      options,
    );

    return {
      data: data,
      message: '',
      isError: false,
    };
  } catch (error) {
    console.error('error ', error);
    return {
      data: null,
      message: 'Failed to update consignment.',
      isError: true,
    };
  }
};
