import { CustomizeOrderComment } from '../custom/customize-order-comment';
import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger, setActiveStepOnBody } from '../utils';

export const paymentStepLoadingConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.PAYMENT_STEP_LOADING,
  handler: ({ detail }) => {
    logger('Payment Step Loading');
    setActiveStepOnBody(detail.activeStepType);

    /*
     * put coupon code and amount into the order comments
     */
    if (detail.cart) {
      const customerMessage = detail.customerMessage;
      console.log('CustomizeOrderComment', { customerMessage });
      new CustomizeOrderComment(detail.cart, customerMessage, detail.refreshCartData);
    }
  },
};
